<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card-deck">
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ families.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Families
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ totalMembers }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Members
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ imperial }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Imperial Units
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ metric }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Metric Units
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      v-b-modal="'createFamily'"
                      ><i class="fas fa-plus"></i> Create Family</b-button
                    >

                    <b-modal
                      id="createFamily"
                      title="Create Family"
                      ok-title="Create"
                      ok-variant="primary"
                      @ok="create()"
                    >
                      <b-form-group
                        id="input-name"
                        label="Family Name"
                        label-for="input-name"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="family.name"
                          type="text"
                          required
                          placeholder="e.g. Smith Family"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Unit System"
                        label-for="family-unit-system"
                      >
                        <b-form-select
                          v-model="family.unit_system"
                          :options="unitSystems"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group
                        label="Shopping Day"
                        label-for="family-shopping-day"
                      >
                        <b-form-select
                          v-model="family.shopping_day"
                          :options="days"
                        ></b-form-select>
                      </b-form-group>
                    </b-modal>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredFamilies"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'confirmDelete' + data.item['.key']"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>

                      <b-modal
                        :id="'confirmDelete' + data.item['.key']"
                        title="Delete Family"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteFamily(data.item['.key'])"
                      >
                        Are you sure you want to delete family
                        {{ data.item.name }}? This cannot be undone.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form @submit.prevent="isNew ? create() : update()">
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="Family" active>
                        <div class="form-wrapper">
                          <b-form-group
                            id="input-name"
                            label="Family Name"
                            label-for="input-name"
                          >
                            <b-form-input
                              id="input-name"
                              v-model="family.name"
                              type="text"
                              required
                              placeholder="e.g. Smith Family"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="Unit System"
                            label-for="family-unit-system"
                          >
                            <b-form-select
                              v-model="family.unit_system"
                              :options="unitSystems"
                            ></b-form-select>
                          </b-form-group>
                          <b-form-group
                            label="Shopping Day"
                            label-for="family-shopping-day"
                          >
                            <b-form-select
                              v-model="family.shopping_day"
                              :options="days"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <b-tab title="Members">
                        <div class="form-wrapper text-center">
                          <b-button
                            variant="outline-success"
                            size="sm"
                            v-b-modal="'addFamilyMember'"
                            class="mb-3"
                            ><i class="fas fa-plus"></i> Add Member</b-button
                          >

                          <b-modal
                            id="addFamilyMember"
                            title="Add Family Member"
                            ok-title="Add"
                            ok-variant="primary"
                            @ok="addMember()"
                          >
                            <b-form-group label="User">
                              <autocomplete
                                :search="searchUsers"
                                :get-result-value="getUserValue"
                                overwrite-class="user"
                                class="mb-3"
                                placeholder="e.g. John Smith"
                                @change="updateUser"
                              ></autocomplete>
                            </b-form-group>
                            <b-form-group>
                              <b-form-checkbox
                                v-model="newMember.accepted"
                                switch
                              >
                                Accepted
                              </b-form-checkbox>
                            </b-form-group>
                            <b-form-group>
                              <b-form-checkbox v-model="newMember.owner" switch>
                                Owner
                              </b-form-checkbox>
                            </b-form-group>
                          </b-modal>

                          <b-form-group class="text-left">
                            <b-list-group>
                              <b-list-group-item
                                v-for="member in memberList"
                                :key="member.user_uid"
                              >
                                {{ member.name }}
                                <b-form inline class="float-right">
                                  <b-form-checkbox
                                    v-model="member.accepted"
                                    class="mr-3"
                                    size="sm"
                                    switch
                                  >
                                    <i>Accepted</i>
                                  </b-form-checkbox>
                                  <b-form-checkbox
                                    v-model="member.owner"
                                    class="mr-3"
                                    size="sm"
                                    switch
                                  >
                                    <i>Owner</i>
                                  </b-form-checkbox>
                                  <b-button
                                    variant="outline-danger"
                                    size="sm"
                                    @click="removeMember(member.user_uid)"
                                    ><i class="far fa-trash-alt"></i
                                  ></b-button>
                                </b-form>
                              </b-list-group-item>
                            </b-list-group>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            type="submit"
                            variant="outline-success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import Autocomplete from "@/components/Autocomplete";
import Users from "@/firebase/users";

export default {
  name: "Families",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    Autocomplete
  },
  data() {
    return {
      bind: [{ ref: "families", target: "families" }],
      loading: false,
      saving: false,
      families: [],
      familyKey: null,
      family: { members: {} },
      newMember: {
        user: {},
        accepted: false,
        owner: false
      },
      users: [],
      isNew: false,
      fields: [
        {
          key: ".key",
          label: "Key",
          sortable: false
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "unit_system",
          label: "Unit System",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      showEdit: false,
      searchText: "",
      unitSystems: [
        { text: "Imperial", value: "imperial" },
        { text: "Metric", value: "metric" }
      ],
      days: [
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" }
      ]
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    filteredFamilies() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.families.filter(family => {
          let showFamily = false;
          if (
            family[".key"].toLowerCase().includes(searchText) ||
            family.name.toLowerCase().includes(searchText)
          ) {
            showFamily = true;
          }
          if ("members" in family) {
            Object.keys(family.members).forEach(member => {
              if (
                String(member.name)
                  .toLowerCase()
                  .includes(searchText)
              ) {
                showFamily = true;
              }
            });
          }
          return showFamily;
        });
      } else {
        return this.families;
      }
    },
    totalMembers() {
      return this.families
        .map(family => {
          return Object.keys(family.members).length;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    imperial() {
      return this.families.filter(family => {
        return family.unit_system !== "metric";
      }).length;
    },
    metric() {
      return this.families.filter(family => {
        return family.unit_system === "metric";
      }).length;
    },
    memberList() {
      const members = [];
      for (const [key, value] of Object.entries(this.family.members)) {
        const member = {
          user_uid: key,
          ...value
        };
        members.push(member);
      }
      return members;
    }
  },
  watch: {
    families: {
      immediate: true,
      handler(families) {
        families.forEach((family, index) => {
          if (!family.members || typeof family.members !== "object") {
            this.families[index].members = {};
          }
          family.unit_system =
            family.unit_system === "metric" ? family.unit_system : "imperial";
        });
      }
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      const u = new Users();
      u.getUsers()
        .then(resp => {
          this.users = [...resp.data.users];
          this.loading = false;
        })
        .catch(error => {
          console.error("Unable to fetch users", error);
          this.loading = false;
        });
    },
    searchUsers(input) {
      if (input.length < 1) {
        return [];
      }
      return this.users.filter(user => {
        return (
          user.auth.displayName.toLowerCase().startsWith(input.toLowerCase()) ||
          user.auth.email.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatUser(user) {
      if (user && typeof user === "object" && "auth" in user) {
        return user.auth.displayName + " (" + user.auth.email + ")";
      } else {
        const u = this.users[this.getIndex(this.users, user, "uid")];
        if (u && "auth" in u) {
          return u.auth.displayName + " (" + u.auth.email + ")";
        } else {
          return u;
        }
      }
    },
    getUserValue(result) {
      return this.formatUser(result);
    },
    updateUser(value) {
      const user = { ...value };
      if (user && "auth" in user) {
        this.newMember.user = {
          uid: user.auth.uid,
          name: user.auth.displayName
        };
      }
    },
    edit(family) {
      this.isNew = false;
      if (!family.members || typeof family.members !== "object") {
        family.members = {};
      }
      this.familyKey = family[".key"];
      this.family = { ...family, members: { ...family.members } };
      this.showEdit = true;
    },
    closeEdit() {
      this.family = { members: {} };
      this.showEdit = false;
    },
    newFamily() {
      this.isNew = true;
      this.family = { members: {} };
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      const family = { ...this.family };
      this.createObject("families", family)
        .then(() => {
          this.saving = false;
          window.toastr.success("Family created successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error(
            "An error occurred creating the family: " + error
          );
          console.error("Unable to create family", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const family = { ...this.family };
      if (this.familyKey && this.familyKey.length > 0) {
        this.updateObject("families", this.familyKey, family)
          .then(() => {
            this.saving = false;
            window.toastr.success("Family updated successfully.");
          })
          .catch(error => {
            this.saving = false;
            window.toastr.error(
              "An error occurred updating the family: " + error
            );
            console.error("Unable to update family", this.familyKey, error);
          });
      }
      return true;
    },
    deleteFamily(key) {
      this.saving = true;
      this.deleteObject("families", key)
        .then(() => {
          this.saving = false;
          window.toastr.success("Family deleted successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error(
            "An error occurred deleting the family: " + error
          );
          console.error("Unable to delete family", key, error);
        });
      return key;
    },
    addMember() {
      this.family.members[this.newMember.user.uid] = {
        name: this.newMember.user.name,
        accepted: this.newMember.accepted,
        owner: this.newMember.owner
      };
      this.newMember = { user: {}, accepted: false, owner: false };
      this.family = { ...this.family, members: { ...this.family.members } };
      this.$forceUpdate();
    },
    removeMember(uid) {
      delete this.family.members[uid];
      this.family = { ...this.family, members: { ...this.family.members } };
      this.$forceUpdate();
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style>
.wrapper {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.user-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.user-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.user-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  list-style-type: none;
  cursor: pointer;
  z-index: 999;
}

.user-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}
</style>
